import React, { useEffect, useState } from "react";
import PriceComponent from "./../components/price.component";

import CaptchaCheckoutForm from "../components/captcha.form.component";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const TariffPage: React.FC<any> = ({ evseID }) => {
  const { t } = useTranslation(["common", "tariff", "price"]);

  // const [token, tokenSet] = useState<CSRFToken | undefined>()

  const [isSetting, setIsSetting] = useState(false);

  useEffect(() => {
    // fetch token
    const fetchToken = async (id: string) => {
      try {
        setIsSetting(true);
        setIsSetting(false);
      } catch (error) {
        console.log("error", error);
      }
    };

    if (!isSetting && evseID !== undefined) {
      fetchToken(evseID);
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {evseID} {t("tariff:title")}
          </title>
        </Helmet>

        <div className="pt-5 tracking-tight text-gray-900 dark:text-gray-200">
          <div className="inline-flex gap-4  items-center w-full">
            <FontAwesomeIcon icon={faEuroSign} />
            <PriceComponent evseID={evseID} children={t("price:info")} />
          </div>
        </div>

        <div className="flex flex-row pt-2">
          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <CaptchaCheckoutForm evseID={evseID} />
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};
export default TariffPage;
