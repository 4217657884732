import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@reach/router";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const CancelPage: React.FC<any> = ({ evseID }) => {
  const { t } = useTranslation(["common", "cancel"]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {evseID} {t("common:status")}
          </title>
        </Helmet>

        <ol className=" border-l border-gray-200 dark:border-gray-700 mt-10">
          <li className="mb-10 ml-6">
            <span className="flex absolute -left-3 justify-center items-center w-6 h-6  dark:text-white">
              <svg
                className="h-8 w-8 "
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" /> <line x1="15" y1="9" x2="9" y2="15" /> <line x1="9" y1="9" x2="15" y2="15" />
              </svg>
            </span>

            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white"> {t("cancel:payment_status")}</h3>

            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{t("cancel:payment_canceled")}</p>
          </li>
        </ol>

        <div className="flex justify-center">
          <Link type="button" to="../tariff">
            <button className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <FontAwesomeIcon icon={faRepeat} />
              &nbsp;
              {t("cancel:back_to_tariff")}
            </button>
          </Link>
        </div>
      </HelmetProvider>
    </>
  );
};

export default CancelPage;
