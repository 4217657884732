import React, { useEffect, useState } from "react";
import TopComponent from "../components/top.component";
import HeaderComponent from "../components/header.component";
import "leaflet/dist/leaflet.css";
import { AdhocTextInterface, ConnectorDetailsInterface } from "../commom/types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import DefaultPage from "./default.page";
import { AppCtx, ContextInterface } from "../commom/app.context";
import { CustomRouterProps } from "../commom/props";
import CookieAlert from "../components/cookie.component";
import LinearLoader from "../components/linear_loader.component";

import TranslateComponent from "../components/translate.component";
import axios from "../utils/axios";
import { PricingResponse } from "../commom/pricing";

enum InitializingEnum {
  WillLoad = -1,
  Loading = 0,
  Fetched = 1,
  Errored = 2
}

const ContainerPage: React.FC<CustomRouterProps> = ({ evseID, ...rest }) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(true);

  const [price, setPrice] = useState<PricingResponse | undefined>();
  const [priceLoading, setPriceLoading] = useState<InitializingEnum>(InitializingEnum.WillLoad);

  const [connector, setConnector] = useState<ConnectorDetailsInterface | undefined>();
  const [adhocText, setAdhocText] = useState<AdhocTextInterface | undefined>();

  const [error, setError] = useState<string | undefined>();

  const sysCtx: ContextInterface = {
    evseID: evseID,
    price: price,
    connector: connector,
    connectorUpdatedAt: undefined,
    adhocText: adhocText
  };

  useEffect(() => {
    if (!evseID) {
      return;
    }
    axios
      .get(`/api/evse/${evseID}/validate/`)
      .then((response) => {
        setValidated(response.data);
      })
      .catch((error) => {
        console.log(error);
        setValidated(false);
      })
      .finally(() => {
        setValidating(false);
        setError("Error validating " + error);
      });
  }, [evseID]);

  useEffect(() => {
    if (!validated) {
      return;
    }

    axios
      .get(`/api/evse/${evseID}/get-connector-details/`)
      .then((response) => {
        setConnector(response.data);
        sysCtx.connector = response.data;
      })
      .catch((error) => {
        console.log(error);
        setValidated(false);
        setError("Error getting connector details " + error);
      });
  }, [validated]);

  useEffect(() => {
    if (!connector) {
      return;
    }

    setPriceLoading(InitializingEnum.Loading);

    axios
      .get(`/api/evse/${evseID}/get-price-details/`)
      .then((response) => {
        setPrice(response.data);
        setPriceLoading(InitializingEnum.Fetched);
        sysCtx.price = response.data;
      })
      .catch((error) => {
        setPriceLoading(InitializingEnum.Errored);
        setError("Error getting price details " + error);
      });
  }, [connector]);

  useEffect(() => {
    if (!price) {
      return;
    }

    axios
      .get(`/api/evse/${evseID}/get-design/`)
      .then((response) => {
        setAdhocText(response.data);

        setAdhocText({
          en: response.data.stripeEn,
          de: response.data.stripeDe
        });

        sysCtx.adhocText = adhocText;
      })
      .catch((error) => {
        setError("Error getting adhoc text " + error);
      });
  }, [price]);

  if (validating) {
    return <LinearLoader />;
  }

  if (!validating && !validated) {
    // show skeleton
    return <DefaultPage />;
  }

  if ((priceLoading == InitializingEnum.Fetched || priceLoading == InitializingEnum.Errored) && (!price || price.status !== 200)) {
    return <DefaultPage />;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{evseID}</title>
        </Helmet>
        <div className="p-6 max-w-lg bg-white dark:bg-gray-800 pb-20">
          <div className="relative">
            <AppCtx.Provider value={sysCtx}>
              <TopComponent evseID={evseID} />
              <HeaderComponent evseID={evseID} />
              {rest.children}
            </AppCtx.Provider>
            <CookieAlert />
          </div>
          <TranslateComponent />
        </div>
      </HelmetProvider>
    </>
  );
};
export default ContainerPage;
