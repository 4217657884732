import React, { useContext, useEffect } from "react";

import { Link } from "@reach/router";
import PriceComponent from "./../components/price.component";
import InfoPopup from "../popups/info.popup";
import { AppCtx } from "../commom/app.context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Map from "../components/map.component";
import { useTranslation } from "react-i18next";
import { MainDetailsInterface } from "../commom/types";
import axios from "../utils/axios";
import { CustomRouterProps } from "../commom/props";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign, faMapMarker, faPhone, faPlug } from "@fortawesome/free-solid-svg-icons";

const DetailsPage: React.FC<CustomRouterProps> = ({ evseID }) => {
  const [data, setData] = React.useState<MainDetailsInterface>();

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    if (!evseID) {
      return;
    }
    axios
      .get(`/api/evse/${evseID}/get-location-details/`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, [evseID]);

  const { t } = useTranslation(["common", "details", "price"]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {evseID} {t("details:title")}
          </title>
        </Helmet>

        <div className="flex flex-col gap-4 py-5 tracking-tight text-gray-900 dark:text-gray-200">
          <Map evseID={evseID} />

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4 items-center w-full">
              <FontAwesomeIcon icon={faMapMarker} />
              {data?.street !== undefined ? (
                data?.street + "  " + data?.houseNumber
              ) : (
                <div className="w-40">
                  <Skeleton className="animate-pulse" count={2} />
                </div>
              )}
              <br />
              {data?.postCode} {data?.city}
            </div>
          </div>

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <FontAwesomeIcon icon={faPlug} />

              {appCtx?.connector?.connectorPlugType !== undefined ? (
                <div>
                  <span className="mr-2">{appCtx?.connector?.connectorPlugType}</span>
                  <span className="wx-4">{appCtx?.connector?.connectorPower}kW</span>
                  <span className="mx-2">({appCtx?.connector?.connectorAmpere}A)</span>
                </div>
              ) : (
                <div className="w-40 ml-2">
                  <Skeleton className="animate-pulse" />
                </div>
              )}
            </div>
          </div>
          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <FontAwesomeIcon icon={faPhone} />

              {data?.phone !== undefined ? (
                <a href={"tel:" + data?.phone} className="hover:underline ">
                  {data?.phone}
                </a>
              ) : (
                <div className="w-40">
                  <Skeleton className="animate-pulse" />
                </div>
              )}
            </div>
          </div>

          <div className="tracking-tight text-gray-900 dark:text-gray-200">
            <div className="inline-flex gap-4  items-center w-full">
              <FontAwesomeIcon icon={faEuroSign} />
              <PriceComponent evseID={evseID} children={t("price:info")} />
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-row pt-8">
          <div className="mx-1 px-12"></div>

          <InfoPopup />

          <Link type="button" to="tariff">
            <button
              type="button"
              className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t("common:next")} &nbsp;
              <svg
                className="w-3 h-3 text-white mr-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
              </svg>
            </button>
          </Link>
        </div>
      </HelmetProvider>
    </>
  );
};
export default DetailsPage;
